import React from 'react';
import { Form, withFormik, FastField, ErrorMessage, Field } from 'formik';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { Row, Col } from '../../ui/wrapper'
import { FormGroup, Input, Textarea, Error } from '../../ui/form'
import Button from '../../ui/button'
import { Center, InputField } from './styles';
import Anchor from '../../../components/ui/anchor'


const ContactForm = ({ setFieldValue, isSubmitting, values, errors, touched }) => (
  <Form
    name="contact-us"
    method="post"
    data-netlify="true"
    data-netlify-recaptcha="true"
    data-netlify-honeypot="bot-field"
  >
    <Row>
      <Col lg={12}>
        <FormGroup mb="20px">
          <Field
            name="tunnel"
            id="tunnel"
            aria-label="tunnel"
            as="select"
            hover="2"
            error={touched.tunnel && errors.tunnel}
          >
            <option value="">How did you find out about us? *</option>
            <option value="Web search">I did a web search and you poped up</option>
            <option value="Social media">I saw you on social media (Facebook, Instagram, LinkedIn...)</option>
            <option value="Platforms">I saw your work on platforms (Clutch, Awwwards...)</option>
            <option value="Article">I read an article about you</option>
            <option value="Recommendation">I got a recommendation</option>
            <option value="Other">Other</option>
          </Field>
          <ErrorMessage component={Error} name="tunnel" />
        </FormGroup>
      </Col>
    </Row>
    <Row gutters={{ lg: 20 }}>
      <Col lg={6}>
        <FormGroup mb="20px">
          <Input
            as={FastField}
            type="text"
            name="name"
            component="input"
            aria-label="name"
            placeholder="Your Name *"
            error={touched.name && errors.name}
          />
          <ErrorMessage component={Error} name="name" />
        </FormGroup>
      </Col>
      <Col lg={6}>
        <FormGroup mb="20px">
          <Input
            as={FastField}
            type="text"
            name="company"
            component="input"
            aria-label="company"
            placeholder="Name of your Company *"
            error={touched.company && errors.company}
          />
          <ErrorMessage component={Error} name="company" />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col lg={12}>
        <FormGroup mb="20px">
          <Input
            id="email"
            aria-label="email"
            component="input"
            as={FastField}
            type="email"
            name="email"
            placeholder="Email address *"
            error={touched.email && errors.email}
          />
          <ErrorMessage component={Error} name="email" />
        </FormGroup>
      </Col>
    </Row>
    <Row>
      <Col lg={12} mb="20px">
        <p>Need NDA first? E-mail us at <Anchor path="mailto:hello@sixvertex.com"> hello@sixvertex.com</Anchor></p>
      </Col>
    </Row>
    <Row>
      <Col lg={12}>
        <FormGroup mb="20px">
          <Textarea
            as={FastField}
            component="textarea"
            aria-label="message"
            id="message"
            rows="8"
            type="text"
            name="message"
            placeholder="Tell us about the project *"
            error={touched.message && errors.message}
          />
          <ErrorMessage component={Error} name="message" />
        </FormGroup>
      </Col>
    </Row>
    {values.name && values.email && values.message && (
      <InputField>
        <FastField
          component={Recaptcha}
          sitekey='6LfNndUZAAAAAL8_y_pmOMr7S-NygRaveUAPJ-FK'
          name="recaptcha"
          onChange={value => setFieldValue('recaptcha', value)}
        />
        <ErrorMessage component={Error} name="recaptcha" />
      </InputField>
    )}
    {values.success && (
      <InputField>
        <Center>
          <p><span role="img" aria-label="tick">✅</span> Your message has been successfully sent, Our team will contact you ASAP. In the meantime, feel free to contact us on social channels or call us if your project requires immediate attention.</p>
        </Center>
      </InputField>
    )}
    <Center>
      <p><small>By submitting this form, you confirm that you have read and agree to our <Anchor path="/privacy-policy">Privacy Policy.</Anchor></small></p>
      <Button secondary type="submit" disabled={isSubmitting}>
        Send Message
      </Button>
    </Center>
  </Form>
);

export default withFormik({
  mapPropsToValues: () => ({
    tunnel: '',
    name: '',
    company: '',
    email: '',
    message: '',
    recaptcha: '',
    success: false,
  }),
  validationSchema: () =>
    Yup.object().shape({
      tunnel: Yup.string().required('Source of referral is required'),
      name: Yup.string().required('Name is required'),
      company: Yup.string().required('Company name is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      message: Yup.string().required('Message is required'),
      recaptcha: Yup.string().required('🤖 Robots are not welcome yet!'),
    }),
  handleSubmit: async ({ tunnel, name, company, email, message, recaptcha }, { setSubmitting, resetForm, setFieldValue }) => {
    try {
      const encode = data =>
        Object.keys(data)
          .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
          .join('&');
      await fetch('/?no-cache=1', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact-us',
          tunnel,
          name,
          company,
          email,
          message,
          'g-recaptcha-response': recaptcha,
        }),
      });
      await setSubmitting(false);
      await setFieldValue('success', true);
      setTimeout(() => resetForm(), 3000);
    } catch (err) {
      setSubmitting(false);
      setFieldValue('success', false);
      alert('Something went wrong, please try again!') // eslint-disable-line
    }
  },
})(ContactForm);





// import React, { useState } from 'react'
// import axios from 'axios'
// import { useForm } from 'react-hook-form'
// import { Row, Col } from '../../ui/wrapper'
// import useFormUrl from '../useFormUrl'
// import Form, { FormGroup, Input, Textarea, Error } from '../../ui/form'
// import Button from '../../ui/button'

// const ContactForm = () => {
//     const formUrl = useFormUrl();
//     const { register, handleSubmit, errors } = useForm({
//         mode: "onBlur"
//     })

//     const [serverState, setServerState] = useState({
//         submitting: false,
//         status: null
//     });
//     const handleServerResponse = (ok, msg, form) => {
//         setServerState({
//             submitting: false,
//             status: { ok, msg }
//         });
//         if (ok) {
//             form.reset();
//         }
//     };

//     const onSubmit = (data, e) => {
//         const form = e.target;
//         setServerState({ submitting: true });
//         axios({
//             method: "post",
//             url: formUrl,
//             data: data
//         })
//             .then(r => {
//                 handleServerResponse(true, "Thanks! for contact with us", form);
//             })
//             .catch(r => {
//                 handleServerResponse(false, r.response.data.error, form);
//             });
//     }
//     return (
//         <Form onSubmit={handleSubmit(onSubmit)}>
//             <Row gutters={{ lg: 20 }}>
//                 <Col lg={6}>
//                     <FormGroup mb="20px">
//                         <Input
//                             type="text"
//                             name="name"
//                             id="name"
//                             placeholder="Name *"
//                             ref={register({ required: 'Name is required' })}
//                         />
//                         <Error>{errors.name && errors.name.message}</Error>
//                     </FormGroup>
//                 </Col>
//                 <Col lg={6}>
//                     <FormGroup mb="20px">
//                         <Input
//                             type="email"
//                             name="email"
//                             id="email"
//                             placeholder="Email *"
//                             ref={register({
//                                 required: 'Email is required',
//                                 pattern: {
//                                     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
//                                     message: "invalid email address"
//                                 }
//                             })}
//                         />
//                         <Error>{errors.email && errors.email.message}</Error>
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col lg={12}>
//                     <FormGroup mb="20px">
//                         <Input
//                             type="text"
//                             name="subject"
//                             id="subject"
//                             placeholder="Subject *"
//                             ref={register({ required: 'Subject is required' })}
//                         />
//                         <Error>{errors.subject && errors.subject.message}</Error>
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col lg={12}>
//                     <FormGroup mb="30px">
//                         <Textarea
//                             name="message"
//                             id="message"
//                             placeholder="Your inquiry or comments *"
//                             ref={register({
//                                 required: 'Message is required',
//                                 maxLength: {
//                                     value: 50,
//                                     message: "Maximum length is 50"
//                                 },
//                                 minLength: {
//                                     value: 10,
//                                     message: "Minimum length is 10"
//                                 }
//                             })}
//                         ></Textarea>
//                         <Error>{errors.message && errors.message.message}</Error>
//                     </FormGroup>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col lg={12}>
//                     <Button type="submit" disabled={serverState.submitting}>Send Message</Button>
//                     {serverState.status && (
//                         <p className={`form-output ${!serverState.status.ok ? "errorMsg" : "success"}`}>
//                             {serverState.status.msg}
//                         </p>
//                     )}
//                 </Col>
//             </Row>
//         </Form>
//     )
// }

// export default ContactForm;