import styled from 'styled-components'
// import {device} from '../../../theme'

export const ContactInfoWrapper = styled.div `
    padding: 30px 0 45px 0;
`;

export const InfoBoxWrap = styled.div `
    margin-top: 30px;
`;

export const InfoBox = styled.div ``;